.display-page{
    /* background-image: 
        linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
        url('/public/background/taajmahal3.jpg'); 
    background-size: cover; 
    background-position: center 40%; 
    background-attachment: fixed;  */
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    /* z-index: -1; */
    background: linear-gradient(to bottom, #000000, #000000);
}
.pagecontent{
    display: flex;
    width: 100%;
    height: 100%;
}
.app-container {
    transition: background-color 0.5s ease;
  }
  
  .black-background {
    background-color: black;
    color: white;
  }