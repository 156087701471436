.privacyPolicyPage{
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
}
.privacyPolicy-content{
    width: 100%;
    height: 100%;
    padding: 2%;
}
.privacyPolicyPageHeading{
    color: #b19f70;
    font-size: 2.5rem;
}
.privacyPolicy-date{
    color: #b3a27a;
    font-size: 1.5rem;
}
.privacyPolicy-text {
    width: 90%;
    color: #D3D3D3;
}
.privacyPolicy-text p{
    margin: 2%;
    font-size: 1.2rem;
}
.p-heading{
    font-size: 1.5rem;
    color: #00BCD4;
}

@media (max-width: 800px) {
    .privacyPolicyPageHeading{
        font-size: 1.2rem;
        margin-top: 10%;
    }
    .privacyPolicy-date{
        font-size: 1rem;
    }
    .privacyPolicy-text p{
        font-size: 0.5rem;
    }
    .p-heading{
        font-size: 0.8rem;
    }
}
