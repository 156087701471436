.aboutUsPage{
    width: 100%;
    height: 100%;
    margin: 5%;
}
.aboutus-content{
    width: 100%;
    height: 100%;
    padding: 20px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.aboutUsPageHeading{
    position: absolute;
    font-size: 5rem;
    text-align: center;
    color: #FFEB3B;
    font-family: "Sofia Sans", sans-serif;
    top: 15%;
    z-index: 3;
}
.aboutUs{
    width: 100%;
    height: 100%;
    color: #FAFAFA ;
    margin-top: 5%;
    padding: 5%;
    border-top: 2px solid #2C2C2C;
    border-bottom: 2px solid #3f3f3f;
    font-size: 1rem;
}
@keyframes scaleInOut {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
.aboutus-image{
    z-index: 0;
    width: 80%;
    height: 60vh;
    border-radius: 30px;
    animation: scaleInOut 5s ease-in-out forwards;
    opacity: 0.3;
}
.aboutUs-text p{
    /* font-family: "Montserrat", sans-serif; */
    margin: 2%;
    font-size: 1.2rem;
}
ol li{
    margin: 10px 0;
}
.bold{
    font-size: 1.1rem;
    font-weight: 700;
}


@media (max-width: 800px) {
    .aboutus-image{
        height: 50vh;
    }
    .aboutUsPageHeading{
        font-size: 2rem;
    }
    .aboutUs-text p{
        font-size: 0.8rem;
    }
    .bold{
        font-size: 0.9rem;
    }
}

