.frontpage-container{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    background: linear-gradient(to bottom, #000000, #000000);    
    font-family: "Sofia Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.frontpage-1,.frontpage-2{
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 2% 5%;
}
@keyframes scaleInOut {
    0% {
        background-size: 150%;
    }
    100% {
        background-size: 100%; /* Back to original size */
    }
}
.frontpage-1{
    background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
    url('/public/background/taajmahal3.jpg'); 
    background-size: cover; 
    background-position: center ; 
    margin: 5% 0;
    border-radius: 20px;
    height: 80vh;
    width: 90%;
    animation: scaleInOut 5s forwards;
}
  @keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px); /* Start from 30px below */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at the normal position */
    }
}
.frontpage-title{
    width: 100%;
    font-family: "Sofia Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    color: #D3D3D3;
    margin-top: 10%;
    text-align: center;
    animation: slideUp 1.5s ease-out forwards;
}
.frontpage-search,.frontpage2-innerdiv{
    display: flex;
    justify-content: space-evenly;
    color: white;
    width: 80%;
    padding: 4%;
    padding-top: 3%;
    border-radius: 40px;
    margin: 5%;
}
.destination,.time,.days,.submit-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center; 
}
.destination{
    /* min-width: 250px; */
    position: relative;
}
.search-input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}
.dropdown {
    position: absolute;
    top: 105%; /* Directly below the input */
    left: -10%;
    width: 120%;
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto;
    background-color: #2C2C2C;
    border-top: none;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #272727;
}
.dropdown-item-outer {
    padding: 8px;
    cursor: pointer;
}
.dropdown-item{
    padding: 5px;
    font-size: 0.8rem;
    margin-left: 20px;
}

.dropdown-item-outer:hover {
    background-color: #222222;
    border-radius: 30px;
}
/* Webkit-based browsers (Chrome, Safari, Edge) */
.dropdown::-webkit-scrollbar {
    width: 4px; /* Adjust width to make it narrower */
    right: -5%;
}

.dropdown::-webkit-scrollbar-thumb {
    background-color: #2c2c2c; /* Scrollbar thumb color */
    border-radius: 4px;     /* Rounded edges for thumb */
}

.dropdown::-webkit-scrollbar-thumb:hover {
    background-color: #2c2c2c; /* Darker color on hover */
}

/* Firefox */
.dropdown {
    scrollbar-width: thin;          /* Use 'thin' for narrow scrollbar */
    scrollbar-color: #1d1d1d #2C2C2C;  /* Thumb color #888 and track color #ffffff */
}

.frontpage-search{
    margin-top: 2%;
    position: relative;
}

.error-message{
    position: absolute;
    top: 10px;
    color: red;
}

.search-input{
    padding: 7%;
    border-radius: 30px;
    border: 2px solid #29D9D5;
    background-color: #2C2C2C;
    color: whitesmoke;
    font-size: 1rem;
}
.submit-button{
    background-color: #2C2C2C;
    border: 2px solid #29D9D5;
    color: #29D9D5;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
}

.submit-button:hover{
    border: 2px solid #7bfcfa;
    color: #7bfcfa;
}
.frontpage2-innerdiv{
    width: 90%;
    background-color: transparent;
}
.frontpage2-left{
    margin-right: 5%;
    width: 40%;
}
.frontpage2-right{
    margin-left: 5%;
    width: 60%;
}

.slideshow {
    position: relative;
    overflow: hidden;
}
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}
.slideshow-image {
    width: 100%;
    height: 300px;
    width: 100%;
    border-radius: 10px;
    animation: slideIn 1s ease forwards;
}
/* Optional sliding out effect */
.slideshow-image.exit {
    animation: slideOut 1s ease forwards;
}
.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    font-size: 20px;
    z-index: 3;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}
.dots {
    text-align: center;
    margin-top: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dot.active {
    background-color: #ffffff; /* Change to your desired active color */
}
.frontpage2-title{
    color: #00BCD4;
    font-size: 1.5rem;
    margin: 10px 0;
}
.frontpage-4{
    width: 100%;
    margin-top: 5%;
}
.footer-links {
    display: flex;
    justify-content: center;
    padding: 4%;
}

.footer-link {
    margin: 0 5%;
    color: #29D9D5;
    text-decoration: none;
    font-size: 1.5rem;
}

.footer-link:hover {
    text-decoration: underline;
}
.frontpage-3 {
    overflow-x: hidden;
    width: 90%;
    height: 100%;
    position: relative;
}
.frontpage3-innerdiv{
    width: 100%;
    height: 60vh;
    /* z-index: 10; */
}
.sliding-window{
    width: 100%;
    height: 100%;
}

.frontpage-3 .frontpage3-innerdiv .sliding-window .item{
    width: 200px;
    height: 300px;
    position: absolute;
    border-radius: 20px;
    background-position: 50% 50%;
    background-size: cover;
    display: inline-block;
    transition: 0.5s;
    left:0;
}
.frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(1){
    top: 0;
    left: 0;
    transform: translate(0,0);
    border-radius: 20px;
    width: 100%;
    height: 100%;
}
.frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(2){
    top: 10%;
    left: 50%;
}
.frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(3){
    top: 10%;
    left: calc(50% + (1 * 220px));
}
.frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(4){
    top: 10%;
    left: calc(50% + (2 * 220px));
}
.frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(5){
    top: 10%;
    left: calc(50% + (3 * 220px));
}
.item .content{
    position: absolute;
    top: 50%;
    left: 100px;
    width: 300px;
    text-align: left;
    color: white;
    transform: translate(0, -50%);
    font-family: system-ui;
    display: none;
}

.sliding-window .item:nth-child(1) .content{
    display: block;
}

.content button{
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0;
    animation: animate 1s ease-in-out 0.6s 1 forwards;
}

.sliding-window .item .content .name{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0;
    animation: animate 1s ease-in-out 1 forwards;
}
.sliding-window .item .content .des{
    margin-top: 10px;
    margin-bottom: 20px;
    opacity: 0;
    animation: animate 1s ease-in-out 0.3s 1 forwards;
}

@keyframes animate{
    from{
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }
    to{
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}

.button{
    justify-content: center;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
}
.button button{
    width: 40px;
    height: 35px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    border: 1px solid #000;
    transition: 0.3s;
}

.button button:hover{
    background: #ababab;
    color: #fff;
}


@media (max-width: 800px) {
    .frontpage-1{
        height: 70vh;
    }
    .frontpage-container{
        margin-top: 25px;
        padding: 0;
        width: 100%;
    }
    .frontpage-title{
        font-size: 1rem;
    }
    .frontpage-search{
        width: 70%;
        display: flex;
        flex-direction: column;
        border-radius: 40px;
        padding: 10%;
        margin-top: 5%;
    }
    .error-message{
        font-size: 0.5rem;
    }
    .destination,.time,.days,.submit-button-container{
        font-size: 0.7rem;
    }
    .search-input{
        border: 1px solid #29D9D5;
        padding: 5px;
        padding-left: 10px;
        border-radius: 10px;
        font-size: 0.5rem;
    }
    .submit-button{
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 0.7rem;
        margin-top: 7%;
        border: 1px solid #29D9D5;
    }
    .slideshow-image {
        width: 100%;
        height: 200px;
    }
    .frontpage-2{
        margin-top: 20%;
    }
    .frontpage2-innerdiv{
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .frontpage2-left{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .frontpage2-right{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .frontpage2-title{
        font-size: 1rem;
    }
    .frontpage2-desc{
        font-size: 0.8rem;
    }
    .footer-link {
        margin: 0 2%;
        font-size: 0.8rem;
    }
    .frontpage-3 {
        margin-top: 10%;
        margin-right: 5%;
    }
    .frontpage-3 .frontpage3-innerdiv .sliding-window .item{
        width: 100px;
        height: 300px;
    }
    .content button{
        padding: 3px 5px;
        font-size: 0.5rem;
    }
    .item .content{
        width: 100%;
        left: 0;
        text-align: center;
    }
    .sliding-window .item .content .name{
        font-size: 1rem;
    }
    .frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(2){
        display: none;
    }
    .frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(3){
        display: none;
    }
    .frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(4){
        display: none;
    }
    .frontpage-3 .frontpage3-innerdiv .sliding-window .item:nth-child(5){
        display: none;
    }
}


@media screen and (min-width: 800px) and (max-width: 1200px) {

    .destination,.time,.days,.submit-button-container{
        font-size: 1rem;
    }
    
    .search-input{
        padding: 3%;
        border-radius: 20px;
        font-size: 0.7rem;
    }
    .submit-button{
       
        font-size: 1rem;
    }
}
