.titleDiv{
    top: 20px;
    display: flex;
    background-color: #2C2C2C  ;
    width: 100%;
}
.siteTitle{
    display: flex;
    color:  #FFFFFF ;
    font-size: 1rem;
    padding: 10px;
    width: 70%;
}
.siteLogo{
    width: 20px;
    height: 20px;
    margin: 0px 10px;
}
.siteName{
    margin: 5px;
    font-style: normal; 
    font-family: "Playfair Display SC", serif;
    font-weight: 400;
    font-style: normal;
}
.social-links {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    width: 30%;
}

.social-icon {
    margin: 0 10px; 
    font-size: 25px; 
    color: #FFFFFF  ; 
    text-decoration: none; 
    transition: color 0.3s;
}

.social-icon:hover {
    color: #697565; 
}
.instagram{
    background: linear-gradient(45deg, rgb(193, 53, 132), rgb(131, 58, 180), rgb(88, 81, 219), rgb(253, 29, 29), rgb(245, 175, 25));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.youtube {
    background: linear-gradient(45deg, rgb(255, 0, 0), rgb(200, 0, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
@media screen and (min-width: 768px) {
    .siteTitle {
        padding: 15px;
    }
    .siteLogo {
        width: 30px;
        height: 30px;
    }
    .siteName {
        font-size: 1.2rem;
    }
}

/* For smaller screens (mobile devices) */
@media screen and (max-width: 767px) {
    .siteTitle {
        padding: 10px;
        width: 80%;
    }
    .siteLogo {
        width: 20px;
        height: 20px;
    }
    .siteName {
        font-size: 1rem;
    }
    .social-icon {
        font-size: 15px;
    }
    
}