
.tourPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    perspective: 1500px;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 5%;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
}

.tourPageContent {
  position: absolute;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, filter 0.5s ease-in-out;
    opacity: 0;
    width: 60%;
    height: 100%;
  }
  .tourPageContent.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) scale(1.2);
    z-index: 1;
    transform: scale(1.2);
    left: 20%;
    top: 10%;
  }
  .tourPageContent.left, .tourPageContent.right {
    opacity: 0.5;
    visibility: visible;
    filter: blur(5px);
    z-index: 0;
}
.tourPageContent.left h2, .tourPageContent.right h2,
.tourPageContent.left a, .tourPageContent.right a,
.tourPageContent.left button, .tourPageContent.right button{
  visibility: hidden;
}
.tourPageContent.left {
    transform: translateX(-20%) scale(0.8);
}

.tourPageContent.right {
    transform: translateX(20%) scale(0.8); 
}
.tourPageContent .imageDiv{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tourPageContent img {
    width: 350px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
}

.tourPageContent h2 {
    position: absolute;
    color: #7ef8f6;
    font-size: 1.2rem;
    text-align: center;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
}
.tourPageContent a{
    position: absolute;
    width: 500px;
    top: 55%;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
    
}
.tourPageContent .buttonDiv{
  width: 100%;
  display: flex;
  justify-content: center;
}
.tourPageContent button{
    position: absolute;
    top: 60%;
    padding: 7px;
    font-size: 1rem;
    color: #7ef8f6;
    border-radius: 12px;
    border: 2px solid #7ef8f6;
    background-color: #212121;
}

.carouselButton {
    position: absolute;
    transform: translateY(-50%);
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    top: 30%;
  }
.prevButton {
    left: 10%;
    z-index: 5;
  }
  
  .nextButton {
    right: 10%;
    z-index: 5;
  }


  @media (max-width: 768px) {
    .tourPageContent .imageDiv{
      margin-top: 5%;
      height: 40%;
    }
    .tourPageContent img {
      width: 150px;
      height: 200px;
    }
    .carouselButton {
      font-size: 0.5rem;
      padding: 5px 10px;
    }
    .prevButton {
      left: 1%;
    }
    .nextButton {
      right: 1%;
    }
    .tourPageContent {
      margin-top: 10%;
    }
    .tourPageContent h2 {
      font-size: 0.8rem;
      top: 2%;
    }
    .tourPageContent a{
      top: 35%;
      font-size: 0.8rem;
    }
    .tourPageContent button{
      top: 38%;
      font-size: 0.5rem;
      border: 1px solid #7ef8f6;
    }

}

@media (min-width: 769px) and (max-width: 1500px) {
  .prevButton {
    left: 3%;
  }
  .nextButton {
    right: 3%;
  }
  .tourPageContent h2 {
    /* font-size: 0.8rem; */
    top: -3%;
  }
  .tourPageContent .imageDiv{
    margin-top: 2%;
  }
  .tourPageContent a{
    top: 52%;
  }
  .tourPageContent button{
    top: 57%;
  }

}

@media (min-width: 1500px) {
  .tourPageContent a{
    top: 45%;
  }
  .tourPageContent button{
    top: 50%;
  }
}