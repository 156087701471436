
.states-container {
    display: flex; 
    align-items: flex-start;
    position: relative;
    height: 100%;
    left: 0;
    margin: 0;
}
.navbar {
    left: 0;
    width: 200px; 
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(213, 182, 233, 0.2);
    background-color: #2C2C2C  ;
    transition: transform 0.3s ease;
    padding-top: 40px;
    border-bottom-right-radius: 20px;
}
.hidden {
    transform: translateX(-100%); /* Slides the navbar out */
}
.toggle-button {
    background-color: #2C2C2C  ;
    width: 50px;
    top: 0%; 
    font-size: 1rem;
    left: 190px;
    background: transparent;
    cursor: pointer; 
    color: #29D9D5; 
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    position: absolute;
    transition: left 0.3s ease; 
    border-radius: 15px;
    border: 2px solid #29D9D5;
    padding: 10px;
}
.hidden + .toggle-button {
    left: 10px; /* When navbar is hidden, move the button left */
    width: 150px;
    border-radius: 30px;
}
.states-container.hidden{
    width: 0;
    left: 0;
}

.stateName {
    width: 100%;
    padding: 10px 15px;
    margin: 5px 0;
    background-color: #2C2C2C   ;
    color: #ECDFCC     ;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.stateName:hover {
    background-color: #697565   ;
}

.stateName:focus {
    outline: none;
    background-color: #697565  ; 
}


@media (max-width: 768px) {
    .navbar {
        align-items: flex-start;
        padding: 2px;
        width: 100px;
    }

    .stateName {
        margin: 0;
       font-size: 0.7rem;
       padding: 5px 7px;
    }
    .toggle-button {
        width: 25px;
        font-size: 0.6rem;
        left: 105px;
        padding: 5px;
        border-radius: 5px;
        border: 0.5px solid #29D9D5;
    }
    .hidden + .toggle-button {
        width: 100px;
        left: 10px;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .stateName {
        margin: 5px;
    }
}

@media (min-width: 1201px) {
    .stateName {
        margin: 5px;
    }
}