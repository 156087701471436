.itenaryPage{
    background-color: #000000;
    color: #E0E0E0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    min-height: 100vh;
}
.itenaryList{
    margin: 50px 20px;
    /* border-bottom: 2px dashed #151515; */
}
.itenaryPageHeading{
    width: 100%;
    text-align: center;
    color: #29D9D5;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.5rem;
}
.itenaryDayAndMap{
    width: 100%;
    display: flex;
    justify-content: center;
    
}
.itenaryDay{
    font-size: 1.5rem;
    margin: 10px 20px;
    color: #91C5F0;
}
.itenaryMapLink{
    font-size: 1.5rem;
    margin: 10px 20px;
    color: #91C5F0;
    
}
.itenaryMapLink a{
    color: #5BB8D4;
    text-decoration: none;
}
.itenaryMapLink a:hover{
    color: #E0E0E0;
    text-decoration: none;
}


.placesDiv{
    display: flex; 
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
}

.itenaryPlaceDiv{
    margin: 20px;
    width: 100%;
    max-width: 45%;
    border-radius: 20px;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #2C2C2C ;
    display: flex;
    position: relative;
    height: 220px;
    align-items: center;
    border: 1px solid #4A4A4A;
}
.itenaryPlaceDiv.left {
  align-self: flex-start;
}

.itenaryPlaceDiv.right {
  align-self: flex-end;
}

.itenaryPlaceDiv.left::after,
.itenaryPlaceDiv.right::before {
  content: '';
  position: absolute;
  top: 50%;
  width: 8%; /* Adjust width of the connecting line */
  height: 2px;
  background-color: #888; /* Line color */
  transform: translateY(-50%);
}
.itenaryPlaceDiv.left::after{
  right: -8%;
}
.itenaryPlaceDiv.right::before {
  left: -8%;
}
.itenaryPlaceDiv:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}
/* vertical line */
/* Vertical line centered in the middle of .placesDiv */
.placesDiv::before {
  content: '';
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 50%;
  width: 2px; /* Adjust width of the vertical line */
  background-color: #888; /* Line color */
  z-index: 1; /* Ensure line is behind content */
}
.itenaryPlaceName{
    padding: 10px;
    text-align: center;
    color: aliceblue    ;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
}

.itenaryPlaceDescription{
    padding: 5px;
    margin: 10px;
    text-align: start;
    color: #B0BEC5    ;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 1rem;
}
.itenaryPlaceImageDiv {
    /* min-height: 30%; */
    height: 100%;
    min-width: 30%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.itenaryPlaceImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.expandItenaryBtn{
    font-size: 2rem;
    background-color: transparent;
    color: aliceblue;
    border: 2px solid #B0BEC5;
    height: 50%;
    align-self: center;
    padding: 0 10px;
}

@media (max-width: 900px) {
    .itenaryPageHeading{
        font-size: 1rem;
        margin-top: 10%;
    }
    .itenaryDay{
        font-size: 0.8rem;
        margin: 5%;
        color: #91C5F0;
    }
    .itenaryMapLink{
        font-size: 0.8rem;
        margin: 5%;
        color: #91C5F0;
        
    }
    .itenaryList{
        margin: 1%;
    }
    .expandItenaryBtn{
        font-size: 0.8rem;
        padding: 2px 5px;
    }
    .itenaryPlaceDiv{
        width: 100%;
        max-width: 90%;
        height: 90px;
    }
    .itenaryPlaceDiv.left::after,
    .itenaryPlaceDiv.right::before {
        width: 0%; 
    }
    .placesDiv::before {
        width: 0%; 
    }
    .itenaryPlaceName,.itenaryPlaceDescription{
        font-size: 0.5rem;
    }
    .itenaryPlaceDiv.right,.itenaryPlaceDiv.left{
        align-self: flex-start;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {

}

@media (min-width: 1201px) {

}