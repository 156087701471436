
.placesPage{
    margin: 0;
    font-size: 1.5rem;
    padding: 10px;
    height: 100%;
    /* background: black; */
}
.placePageHeading{
    margin-top: 0%;
    padding: 10px;
    text-align: center;
    font-size: 2rem;
    color: #FFFFFF    ;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.7rem;
}
.placesList{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.placeDiv{
    margin: 20px;
    width: 100%;
    max-width: 300px;
    /* border: 2px solid #585858  ; */
    border-radius: 20px;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #2C2C2C ;
    position: relative;
    overflow: hidden; 
}
.placeDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 2px solid transparent; /* Invisible border initially */
    box-sizing: border-box;
    pointer-events: none;
    z-index: 1; /* Make sure it's on top of the content */
    animation: animateBorder 6s linear forwards; 
}
@keyframes animateBorder {
    0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    25% {
        border-top-color: #9e9e9e;
        border-right-color: #9e9e9e;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    50% {
        border-top-color: #9e9e9e;
        border-right-color: #9e9e9e;
        border-bottom-color: #9e9e9e;
        border-left-color: transparent;
    }
    75% {
        border-top-color: #9e9e9e;
        border-right-color: #9e9e9e;
        border-bottom-color: #9e9e9e;
        border-left-color: #9e9e9e;
    }
    100% {
        border-top-color: #9e9e9e;
        border-right-color: #9e9e9e;
        border-bottom-color: #9e9e9e;
        border-left-color: #9e9e9e;
    }
}

.placeDiv:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}
.placeName{
    padding: 10px;
    /* font-size: 1.7rem; */
    text-align: center;
    /* border-top: 2px solid #9e9e9e;
    border-bottom: 2px solid #9e9e9e; */
    color: #FFFFFF    ;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
}
.placeDescription{
    padding: 5px;
    margin: 10px;
    text-align: start;
    color: #868585    ;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 1rem;
}
.placeImageDiv {
    height: 0;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
    overflow: hidden;
    /* border-bottom: 2px solid rgb(197, 197, 197); */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.placeImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hidden + .placesPage{
    left: 0;
    padding-left: 0;
} 
@media (max-width: 768px) {
    .placesPage {
        font-size: 1rem;
        /* height: 100%; */
        /* padding: 0; */
    }

    .placePageHeading {
        font-size: 1rem;
    }

    .placeDiv {
        margin: 10px;
        width: 100%;
        max-width: 90%;
    }

    .placeName {
        font-size: 0.8rem;
    }

    .placeDescription {
        font-size: 0.7rem;
    }
}